import { Gondor } from "../Gondor";

const ACTIONS = {
  DUPLICATE: "/file/duplicate",
  SPLIT: "/file/split",
};

export function duplicate(body: any) {
  return Gondor.API.post(ACTIONS.DUPLICATE, body);
}

export function split(body: any) {
  return Gondor.API.post(ACTIONS.SPLIT, body);
}
