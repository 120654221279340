/**
 * @description A class which store all RegExp
 * static methods.
 */

export default class Regex {
  public static names = /^[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ ][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ -']*[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ ]$/;
  public static surname = /^(:?[dD][eE](:? [a-km-zA-KM-ZÁÉÍÓÚáéíóúÜüñÑ]|(:?[lL]| [lL])(:?[aAoO]|[aAoO][sS]|) [a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]+|[lL](:?[aAoO][a-rt-zA-RT-ZÁÉÍÓÚáéíóúÜüñÑ]|[aAoO][sS][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]))(:?[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ -]+[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ ]|)|[dD](:?[a-dA-D]|[f-zF-ZÁÉÍÓÚáéíóúÜüñÑ])[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]|[dD](:?[a-dA-D]|[f-zF-ZÁÉÍÓÚáéíóúÜüñÑ])[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ -]*[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ ]|(:?[a-cA-C]|[e-zE-ZÁÉÍÓÚáéíóúÜüñÑ])[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ -]*[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]|[dD][eE][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ](?:[aAoO][sS][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]|[b-np-zB-NP-ZÁÉÍÓÚáéíóúÜüñÑ])[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]+|[dD][eE] [lL][b-np-zB-NP-ZÁÉÍÓÚáéíóúÜüñÑ][a-rt-zA-RT-ZÁÉÍÓÚáéíóúÜüñÑ-]+[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ ]*|[a-ce-zA-CE-ZÁÉÍÓÚáéíóúÜüñÑ][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]+|[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]{2}[a-km-zA-KM-ZÁÉÍÓÚáéíóúÜüñÑ][a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]*|[yY] [a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]{2}[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ]+)$/;
  public static email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static phone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
  public static alphanumeric = /^[a-zA-Z0-9ÁÉÍÓÚáéíóúÜüñÑ \-&!?¡¿+.,]{4,}$/;
  public static alpha = /^[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ ]+[a-zA-ZÁÉÍÓÚáéíóúÜüñÑ -]*$/;
  public static numeric = /^[0-9]+$/;
  public static url = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  public static code = /^([a-zA-Z]{3,}){1}(.[a-zA-Z]{3,})+$/;
  public static float = /^\d+(\.\d{2})?$/;
  public static blankspace = /^\S+$/;

  public static validate(regex: string, text: string) {
    return (Regex as any)[regex].test(text);
  }

  public static toString(regex: string) {
    return (Regex as any)[regex]
      .toString()
      .substring(1)
      .slice(0, -1);
  }
}
