
import { Component, Vue } from "vue-property-decorator";
import * as FileServices from "@/api/helpers/File";
import Notify from "@/utils/notifications";
import Regex from "@/utils/regex";
import _ from "underscore";
import * as CSV from "csv-string";

@Component
export default class File extends Vue {
  fullscreenLoading = false;
  currentStep = 1;
  warningText = "Elige 1 o más archivos txt/csv.";
  infoText = "Se ignorará la primer fila de cada archivo.";
  fileList: Array<any> = [];
  rules: Array<any> = [];
  fileJson: any = {
    name: "",
    items: [],
  };
  cleanFileList: Array<any> = [];
  selectedColumns: Array<string> = [];
  types = [
    {
      label: "Correo electrónico",
      regex: Regex.email.toString(),
      type: "email",
    },
    {
      label: "Cualquiera",
      regex: null,
      type: null,
    },
    {
      label: "Sólo números",
      regex: Regex.numeric.toString(),
      type: "numeric",
    },
    {
      label: "Sólo letras",
      regex: Regex.alpha.toString(),
      type: "alpha",
    },
    {
      label: "Teléfono (10 dígitos)",
      regex: Regex.phone.toString(),
      type: "phone",
    },
    {
      label: "Url (con http o https)",
      regex: Regex.url.toString(),
      type: "url",
    },
  ];

  extractDuplicates() {
    if (this.fileList.length >= 1) {
      this.currentStep = 3;
      this.fullscreenLoading = true;
      FileServices.duplicate(this.buildFormData(this.fileList))
        .then(res => {
          this.cleanFileList = res.data.data;
          Notify.successful(res.data.message);
        })
        .catch(error => {
          Notify.error(error);
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    }
  }

  download(index: number) {
    const content = this.cleanFileList[index].data.join("\n");
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([content]));
    link.setAttribute("download", this.cleanFileList[index].name);
    document.body.appendChild(link);
    link.click();
  }

  clearFiles() {
    this.fullscreenLoading = true;
    this.fileList = [];
    this.cleanFileList = [];
    (this.$refs.upload as HTMLFormElement).clearFiles();
    this.fullscreenLoading = false;
    this.currentStep = 1;
    this.selectedColumns = [];
  }

  handlefileList(file: any, fileList: Array<any>) {
    this.fileList = fileList;
    this.getFileHeaders();
  }

  getFileHeaders() {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const text = CSV.parse(e.target.result);
      if (text.length - 1 <= 0) {
        Notify.warning(`El archivo "${this.fileList[0].name}" está vacío.`);
        this.clearFiles();
        return;
      }
      this.fileJson = {
        name: this.fileList[0].name,
        size: this.fileList[0].size,
        lines: text.length - 1,
        items: _.map(text[0], (h, i) => {
          return {
            index: i,
            header: h,
            regex: null,
          };
        }),
      };
      this.selectedColumns = _.map(text[0], h => {
        return h;
      });
    };
    reader.readAsText(this.fileList[0].raw);
  }

  handleSelectionChange(val: Array<any>) {
    this.rules = val;
  }

  buildFormData(files: Array<any>) {
    const formData = new FormData();
    if (this.selectedColumns.length)
      formData.append("columns", this.selectedColumns.join(","));
    if (this.rules.length) {
      this.rules = _.map(this.rules, r => {
        _.extend(r, {
          type: _.filter(this.types, t => {
            return t.regex == r.regex;
          })[0].type,
        });
        return r;
      });
      formData.append("rules", JSON.stringify(this.rules));
    }
    _.each(files, f => {
      formData.append("files", f.raw);
    });
    return formData;
  }

  created() {
    const index = "5.1";
    const title = "Extraer duplicados";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Archivos",
        to: {
          name: "file",
        },
      },
      {
        text: title,
      },
    ]);
  }

  selectAllColumns() {
    this.selectedColumns = _.map(this.fileJson.items, h => {
      return h.header;
    });
  }
}
